import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import yaloImages from '../../assets/images'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
  Paper,
  CircularProgress,
  InputAdornment,
  IconButton,
  Button } from '@material-ui/core/'
import TextField from '@material-ui/core/TextField'
import { Visibility, VisibilityOff } from '@material-ui/icons/'
import classes from './SignIn.module.css'
import { api, apiPortalRh } from '../../services/api'
import { login, loginPortalRh } from '../../services/auth'

class SignIn extends React.Component {
  constructor () {
    super()
    this.state = {
      email: '',
      password: '',
      loading: false,
      redirect: false,
      profile: '',
      error: '',
      showPassword: false,
      isLoading: false
    }
  }

  render () {
    const { email, password } = this.state

    return (
      <div className={classes.loginContainer}>
        <Grid container className={classes.appLoginContainer} direction='row' justify='center' alignItems='center'>
          <Grid item xs={12} style={{ padding: 12, marginBottom: 20 }}>
            <Grid container justify='center'>
              <Link to='/' title='Yalo'>
                <img
                  width='150'
                  src={yaloImages.icone_yalo}
                  alt='YALO'
                  title='YALO'
                />
              </Link>{' '}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ margin: '0 30px' }}>
            <Paper style={{ padding: 12 }}>
              <Grid container justify='center'>
                <Grid item xs={10}>
                  <Typography align='center' variant='h1'>
                    Yalo Benefícios
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='subtitle1' align='center' paragraph>
                    Portal de consulta aos benefícios do cliente
                  </Typography>
                </Grid>
                <form onSubmit={this.handleSignIn}>
                  <Grid item xs={12}>
                    <Grid container justify='center'>
                      <Grid item xs={10}>
                        <TextField
                          required
                          variant='outlined'
                          name='email'
                          value={email}
                          label='E-mail de acesso'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          onChange={event => this.setState({ email: event.target.value })}
                          margin='normal'
                        />
                      </Grid>
                      <Grid item xs={10} style={{ marginTop: 12, marginBottom: 12 }}>
                        <TextField
                          type={this.state.showPassword ? 'text' : 'password'}
                          required
                          name='password'
                          variant='outlined'
                          value={password}
                          label='Senha'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          onChange={event =>
                            this.setState({
                              password: event.target.value,
                              error: ''
                            })
                          }
                          margin='normal'
                          InputProps={{
                            style: { boxSizing: 'border-box' },
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='Toggle password visibility'
                                  onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                                >
                                  {this.state.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      {this.state.error && (
                        <div>
                          <h5 style={{ color: '#E53935' }}>
                            {this.state.error}
                          </h5>
                        </div>
                      )}
                      <Grid
                        item
                        xs={10}
                        style={{ marginTop: 24, marginBottom: 12 }}
                      >
                        <Grid
                          container
                          justify='space-between'
                          alignItems='center'
                        >
                          <Grid item xs={4}>
                            <Link style={{ color: '#4db848' }} to='/forgotpwd'>
                              <Typography variant='subtitle1' color='primary'>
                              Esqueci minha senha
                              </Typography>
                            </Link>
                          </Grid>
                          <Grid item xs={4}>
                            <Grid container justify='flex-end'>
                              <Grid item>
                                <Button
                                  type='submit'
                                  variant='contained'
                                  size='large'
                                  color='primary'
                                  style={{ color: 'white' }}
                                  align='right'
                                  disabled={
                                    this.state.isLoading || !this.state.email || !this.state.password
                                  }
                                >
                                  {
                                    this.state.isLoading
                                      ? <CircularProgress
                                        size={25}
                                        style={!this.state.isLoading ? { display: 'none' } : {}}
                                      />
                                      : 'Entrar'
                                  }
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Paper>
          </Grid>
          {this.state.loading && (
            <div className={classes.loaderView}>
              <CircularProgress
                mode='indeterminate'
                style={{ color: '#4CAF50' }}
              />
            </div>
          )}
          <div>
            <p className={classes.versionTag}>v1.2.0</p>
          </div>
        </Grid>
      </div>
    )
  }

  handleSignIn = async e => {
    e.preventDefault()
    const { email, password } = this.state
    if (!email || !password) {
      this.setState({ error: 'Preencha e-mail e senha para continuar!' })
    } else {
      try {
        this.setState({ isLoading: true })
        // login portal
        const portalrhUser = process.env.PORTALRH_USERNAME
        const portalrhPassword = process.env.PORTALRH_PASSWORD

        const responsePortal = await apiPortalRh.post(`/token`, {
          username: portalrhUser,
          password: portalrhPassword
        })

        loginPortalRh(responsePortal.data.token)

        // login beneficios
        const response = await api.post('v1/admin/login', null, { auth: { username: email, password: password } })
        login(response.headers['x-authorization'])
        window.sessionStorage.setItem('data', JSON.stringify(response.data))

        if (response.data.shouldChangePassword) {
          this.props.history.push({
            pathname: '/senha',
            state: { shouldChangePassword: true }
          })
        } else {
          this.props.history.push({ pathname: '/' })
        }
        this.setState({ isLoading: false })
      } catch (err) {
        const { response } = err

        if (response && response.status === 401) {
          this.setState({ error: 'Email ou senha inválidos', isLoading: false })
        } else {
          this.setState({ error: 'Ocorreu um erro ao realizar login. Tente novamente mais tarde.', isLoading: false })
        }
      }
    }
  }
}

SignIn.propTypes = {
  history: PropTypes.object.isRequired
}

export default withRouter(SignIn)
